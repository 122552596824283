// const base_url = `http://localhost:8993`
const base_url = ``
export {base_url}


const getTotalSubscriptions = `${base_url}/api/get-latest`
export {getTotalSubscriptions}

const getTotalSubOnPercentChange = `${base_url}/api/total`
export {getTotalSubOnPercentChange}


const getTotalRevenue = `${base_url}/api/totalrevenue`
export {getTotalRevenue}