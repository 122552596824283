import logo from './logo.svg';
import './App.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import {Routes,Route} from 'react-router-dom'
import Home from './Pages/Home';
import Logs from './Pages/Logs';
import Header from './Components/Header';

function App() {
  return (
   <div className="app">
   <Header/>
   <Routes>
    <Route path='/' element={<Home/>} />
    <Route path='/checklogs' element={<Logs/>} />
   </Routes>
   </div>
  );
}

export default App;
