import React,{useState,useEffect} from 'react'
import classes from './Home.module.css'
import { Calendar } from 'primereact/calendar';
import { getTotalSubscriptions } from '../Services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'


const Home = () => {
    const [date,setDate] = useState(new Date())
    const [totalSub,setTotalSub] = useState('0')

    const GetTotalSubscriptions=async()=>{
        const data = {
            mis_date: date
        }
        try{
            const res = await axios.post(getTotalSubscriptions,data);
            console.log(res?.data)
            setTotalSub(res?.data.curentdata)
        }
        catch(err){
            console.log(err?.response.data)
            toast.error(err?.response.data)
        }

    }


    useEffect(()=>{
        GetTotalSubscriptions()
    },[date])



  return (
    <div className={classes.container}>
    <div className={classes.sub_container}>
    <div className={classes.date_input}>
    <Calendar value={date} onChange={(e) => setDate(e.value)} className={classes.input}/>
    </div>
    <div className={classes.total_sub_div}>
    <div className={classes.total_sub}>
    <h2>Total Subscriptions</h2>
    <h2>{totalSub}</h2>
    </div>
    </div>
    </div>
    <ToastContainer/>
    </div>
  )
}

export default Home
