import React,{useState,useEffect} from 'react'
import classes from './Logs.module.css'
import Data from '../Components/Data'
import { ListBox } from 'primereact/listbox';
import { Calendar } from 'primereact/calendar';
import axios from 'axios'
import { getTotalRevenue, getTotalSubOnPercentChange } from '../Services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Lottie from "lottie-react";
import loader from '../Animations/loader.json'


const Logs = () => {


    const [data,setData] = useState([]);
    const [ratio,setRatio] = useState('0')
    const [date,setDate] = useState(() => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), 1); // First day of the current month
    });


    const [totalSubscriptions,setTotalSubscriptions] = useState(null)
    const [showtotalSubscriptions,setShowTotalSubscriptions] = useState(false)

    const [loading,setLoading] = useState(true)

    
    

    const Ratios = [
        { name: 'Select Ratio', code: '0', disabled: true },
        { name: '5', code: '5' },
        { name: '10', code: '10' },
        { name: '15', code: '15' },
        { name: '20', code: '20' },
        { name: '25', code: '25' },
        { name: '30', code: '30' },
        { name: '35', code: '35' },
        { name: '40', code: '40' },
        { name: '45', code: '45' },
        { name: '50', code: '50' },
        { name: '55', code: '55' },
        { name: '60', code: '60' },
        { name: '65', code: '65' },
        { name: '70', code: '70' },
        { name: '75', code: '75' },
        { name: '80', code: '80' },
        { name: '85', code: '85' },
        { name: '90', code: '90' },
        
    ];


  

    const GetTotalRevenue=async()=>{

        const apidata = {
            year:date ? date.getFullYear() : '',
            month: date ? date.toLocaleString('default', { month: 'short' }) : ''
        }


        try{
            setLoading(true)
            const res = await axios.post(getTotalRevenue,apidata)
            console.log(res.data)
            setData(res.data)
            setLoading(false)

        }
        catch(err){
            console.log(err) 
            toast.error(err?.response.data.message) 
            setLoading(false)
          
        }
    }

    useEffect(()=>{
        GetTotalRevenue()
    },[date])


    const GetTotalSubByChangingRatio=async()=>{
      try{
        const res = await axios.post(getTotalSubOnPercentChange,{percent:ratio.code});
        console.log(res?.data)
        setTotalSubscriptions(res?.data.totalSubscriptions)

        setShowTotalSubscriptions(true)
      }
      catch(err){
        console.log(err?.response.data)
      }
    }


    useEffect(()=>{
       if(ratio != 0){
        GetTotalSubByChangingRatio()
       }
    },[ratio])

  return (
    <>
    <div className={classes.month_picker}>
    <Calendar value={date} 
    onChange={(e) => setDate(e.value)} 
    view="month" 
    dateFormat="MM (yy)" 
    monthNavigator 
    yearNavigator 
    yearRange="2000:2030" />
    </div>
    <div className={classes.container}>
    <div className={classes.sub_container1}>
    <div className={classes.select_div_container}>  
        <div className={classes.select_div}>
        <ListBox value={ratio} onChange={(e) => setRatio(e.value)} options={Ratios} optionLabel="name" className="w-full md:w-14rem" />

        </div>
    </div>

     <div className={classes.total_sub_div}>
        {
            showtotalSubscriptions  ? 
            <div className={classes.total_sub}>
        <h2>Total Subscriptions</h2>
        <h2>{totalSubscriptions}</h2>
        </div>
        :
        <div className={classes.Nothing}>
        <h2>Nothing to Show</h2>
        </div>

        }
        </div>
    </div>
    <div className={classes.sub_container2}>
   {
    loading ? <div className={classes.animation}>
    <Lottie animationData = {loader} className={classes.animation}/>
    </div>:
    <> <Data data={data} /></>
   }
    </div>
    <ToastContainer/>
    </div>
    </>
  )
}

export default Logs
