import React from 'react'
import classes from './Header.module.css'

const Header = () => {
  return (
    <div className={classes.container}>
    <p>GAMESTATION</p>
    </div>
  )
}

export default Header
